<template>
  <th-wrapper
    v-loading="loading"
    :title="$t('common.titles.general_info.title')"
    class="m-8"
    body-class="p-8 pb-6"
  >
    <!-- Center -->
    <template #center>
      <!-- Active -->
      <el-checkbox
        v-model="form.active"
        :label="$t('pages.product_groups.edit.form.properties.active.label')"
      />
    </template>
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :sm="24" :lg="12">
          <el-row :gutter="20">
            <!-- Product Group Name -->
            <el-col :md="24">
              <el-form-item
                :label="
                  $t('pages.product_groups.edit.form.properties.name.label')
                "
                prop="name"
              >
                <el-input id="name" v-model="form.name" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <!-- Product Group Number -->
            <el-col :md="24">
              <el-form-item
                :label="
                  $t(
                    'pages.product_groups.edit.form.properties.product_group_id.label'
                  )
                "
                prop="product_group_id"
              >
                <el-input
                  id="product_group_id"
                  v-model="form.product_group_id"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <!-- Tax -->
            <el-col :md="12">
              <el-form-item
                :label="
                  $t('pages.product_groups.edit.form.properties.tax.label')
                "
                prop="tax"
              >
                <el-select
                  id="tax"
                  v-model="form.tax"
                  v-cancel-read-only
                  filterable
                  remote
                  clearable
                  reserve-keyword
                  :remote-method="searchTaxes"
                  class="w-full"
                >
                  <el-option
                    v-for="item in taxOptions"
                    :key="item.id"
                    :label="item.computed_name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <!-- Revenue Account -->
            <el-col :md="12">
              <el-form-item
                :label="
                  $t('pages.product_groups.edit.form.properties.account.label')
                "
                prop="account"
              >
                <el-select
                  id="account"
                  v-model="form.account"
                  v-cancel-read-only
                  filterable
                  remote
                  clearable
                  reserve-keyword
                  :remote-method="searchAccounts"
                  class="w-full"
                >
                  <el-option
                    v-for="item in accountsOptions"
                    :key="item.id"
                    :label="item.computed_name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="isTakeawayEnabled" :gutter="20">
            <!-- Takeaway Tax -->
            <el-col :md="12">
              <el-form-item
                :label="
                  $t(
                    'pages.product_groups.edit.form.properties.takeaway.tax.label'
                  )
                "
                prop="takeaway_tax"
              >
                <el-select
                  id="takeaway_tax"
                  v-model="form.takeaway_tax"
                  v-cancel-read-only
                  filterable
                  remote
                  clearable
                  reserve-keyword
                  :remote-method="searchTaxes"
                  class="w-full"
                >
                  <el-option
                    v-for="item in taxOptions"
                    :key="item.id"
                    :label="item.computed_name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <!-- Takeaway Revenue Account -->
            <el-col :md="12">
              <el-form-item
                :label="
                  $t(
                    'pages.product_groups.edit.form.properties.takeaway.account.label'
                  )
                "
                prop="takeaway_account"
              >
                <el-select
                  id="takeaway_account"
                  v-model="form.takeaway_account"
                  v-cancel-read-only
                  filterable
                  remote
                  clearable
                  reserve-keyword
                  :remote-method="searchAccounts"
                  class="w-full"
                >
                  <el-option
                    v-for="item in accountsOptions"
                    :key="item.id"
                    :label="item.computed_name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :sm="24" :lg="12">
          <!-- Display image -->
          <el-form-item :label="$t('common.forms.labels.image')" prop="images">
            <th-image-upload-v2
              id="images"
              v-model="form.images"
              class="w-full"
              resource="productGroups"
              image-key="1x"
              fill-type="contain"
              icon="PictureRounded"
              :height="isTakeawayEnabled ? '288px' : '206px'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import compose from 'just-compose'
import debounce from 'debounce'
import safeGet from 'just-safe-get'
import { currencies } from '@/constants'
import { replaceEmptyPropsWithNull, makeHandleableBody } from '@/utils/objects'
import { h } from 'vue'

function genInitialData() {
  return {
    name: null,
    product_group_id: null,
    tax: null,
    takeaway_tax: null,
    active: true,
    account: null,
    takeaway_account: null,
    images: {},
    color: null
  }
}

export default {
  data() {
    return {
      loading: true,
      valid: false,
      attributes: [],
      availableCurrencies: currencies,
      form: genInitialData(),
      payload: {},
      taxes: [],
      taxOptions: [],
      accounts: [],
      accountsOptions: [],
      dependencies: {
        products: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.product_groups.edit.form.rules.name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        product_group_id: [
          {
            required: true,
            message: this.$t(
              'pages.product_groups.edit.form.rules.product_group_id.required'
            ),
            trigger: 'blur'
          },
          {
            validator: this.validateUniqueProductGroupNumberError,
            trigger: 'blur'
          }
        ]
      }
    },
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    navigationAfterCreation() {
      return this.$store.getters['Config/getNavigationAfterCreation']
    },
    isTakeawayEnabled() {
      return this.$isFeatureEnabled('takeaway')
    }
  },
  watch: {
    'form.product_group_id': function () {
      if (this.productGroupNumberError) this.productGroupNumberError = false
    }
  },
  async mounted() {
    await this.fetchTaxes()
    await this.fetchAccounts()
    if (!this.isNew) await this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        const inst = th.productGroups()

        this.loading = true
        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.product_group.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    handleItem(item) {
      this.payload = { ...item }
      const cleanedPayload = pick(item, Object.keys(this.form))

      this.form = {
        ...this.form,
        ...cleanedPayload
      }
    },
    submitForm() {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })
        }

        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },
    async handleDelete() {
      try {
        await this.confirmMasterdataResourceDelete(this.payload)
      } catch (error) {
        // no-op
        return
      }
      this.deleteProductGroup(this.payload)
    },
    async deleteProductGroup(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.product_group.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.product_group.singular')
      })

      try {
        const inst = th.productGroups()
        await inst.delete(payload.id)

        this.$message({
          type: 'success',
          message: successMessage
        })

        this.$router.push({ name: 'products-product-groups-list' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async confirmMasterdataResourceDelete(payload) {
      await this.fetchDependencies(payload)

      const depsTranslations = {
        products: this.$t('pages.products.title')
      }

      const affectedDependencies = Object.entries(this.dependencies).map(
        (dep) => {
          const [key, value] = dep
          const text = `${depsTranslations[key]}: ${value}`
          return h('li', null, text)
        }
      )

      await this.$confirm('', this.$t('common.delete_confirm.title'), {
        customClass: 'masterdata-confirm-box',
        confirmButtonText: this.$t(
          'common.interactions.buttons.masterdata_delete_confirm'
        ),
        cancelButtonText: this.$t('common.interactions.buttons.cancel'),
        type: 'warning',
        message: h('div', {}, [
          h('p', {}, this.$t('common.masterdata_delete_confirm.content')),
          h(
            'p',
            {
              class: 'confirm-message-affected-deps',
              style: 'margin-top: 1rem;'
            },
            [
              h(
                'span',
                {},
                `${this.$t('common.masterdata_delete_confirm.dependencies')}:`
              ),
              h('ul', { style: 'margin: 0.2rem 0;' }, affectedDependencies)
            ]
          )
        ])
      })
    },
    async fetchDependencies(payload) {
      try {
        this.loading = true
        const { data } = await th
          .dependencies()
          .get({ type: 'product_group', resource: payload.id })
        this.dependencies = { ...this.dependencies, ...data[0] }
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    async alter(id) {
      const payload = {
        ...this.payload,
        ...this.form
      }

      const cleanPayload = compose(
        (p) => makeHandleableBody(p, this.form),
        replaceEmptyPropsWithNull
      )

      try {
        const inst = th.productGroups()
        this.loading = true
        const { data = {} } = await inst.put(payload.id, cleanPayload(payload))

        if (data.id) {
          this.handleItem(data)
        }

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.product_group.singular')
          })
        })
      } catch (err) {
        this.checkUniqueProductGroupNumberError(err)
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.product_group.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    async create() {
      const payload = {
        ...this.form
      }

      try {
        const inst = th.productGroups()
        this.loading = true

        // we will handle currency and timezone as configuration, TODO: implement
        const { data = {} } = await inst.create(
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)

          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.product_group.singular')
            })
          })
          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'products-product-groups-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'products-product-groups-list' })
          }
        }
      } catch (err) {
        this.checkUniqueProductGroupNumberError(err)
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.product_group.singular')
          })
        })
      } finally {
        this.loading = false
        this.$ampli.eventWithBaseProps('productGroupCreated')
      }
    },
    searchTaxes: debounce(function (text) {
      if (!text) return
      if (text.length < 2) return
      this.taxOptions = this.taxes.filter((item) => {
        return item.computed_name.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
    }, 50),
    searchAccounts: debounce(function (text) {
      if (!text) return
      if (text.length < 2) return
      this.taxOptions = this.taxes.filter((item) => {
        return item.computed_name.toLowerCase().indexOf(text.toLowerCase()) > -1
      })
    }, 50),
    async fetchTaxes() {
      try {
        this.loading = true
        const { taxes } = await this.$resourceFetch('taxes')
        if (!this.taxes || !Array.isArray(this.taxes)) {
          this.taxes = []
          return
        }

        this.taxes = this.taxOptions = taxes.map((item) => {
          return {
            ...item,
            computed_name: [item.fa_account_number, item.name].join(' – ')
          }
        })
      } catch (err) {
        this.taxes = []
        this.$logException(err, {
          trackError: false,
          message: `Could not fetch taxes: ${err.message}`
        })
      } finally {
        this.loading = false
      }
    },
    async fetchAccounts() {
      try {
        this.loading = true
        const { accounts } = await this.$resourceFetch({
          resource: 'accounts',
          query: { type: 'revenue' }
        })

        if (!this.taxes || !Array.isArray(this.taxes)) {
          this.taxes = this.accountsOptions = []
          return
        }

        this.accounts = this.accountsOptions = accounts.map((item) => {
          return {
            ...item,
            computed_name: [item.fa_account_number, item.name].join(' – ')
          }
        })
      } catch (err) {
        this.accounts = this.accountsOptions = []
        return this.$logException(err, {
          trackError: false,
          message: `Could not fetch accounts: ${err.message}`
        })
      } finally {
        this.loading = false
      }
    },
    checkUniqueProductGroupNumberError(err) {
      const errorMsg = safeGet(err, 'properties.error.response.data.msg') || ''
      // Product group number \"1\" already exists"
      if (errorMsg.includes('already exists')) {
        this.productGroupNumberError = true
        this.$refs.form.validate()
      }
    },
    validateUniqueProductGroupNumberError(rule, value, callback) {
      if (this.productGroupNumberError) {
        callback(
          new Error(
            this.$t(
              'pages.product_groups.edit.form.rules.product_group_id.unique',
              {
                product_group_id: this.form.product_group_id
              }
            )
          )
        )
      } else {
        callback()
      }
    }
  }
}
</script>
