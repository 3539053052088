<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <product-group-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import ProductGroupForm from './components/form.vue'

export default {
  name: 'ProductGroupEdit',
  components: {
    ProductGroupForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'products:product_groups',
        returnedPath: { name: 'products-product-groups-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
